import React, { useState } from 'react';
import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
} from 'reactstrap';
import '../../App.css'


const Example = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <React.Fragment>
            <Navbar style={{backgroundColor: "#CCC"}} dark expand="md">
                <div className="container" style={{marginLeft: '0px'}}>
                    <NavbarBrand href="/">
                        <img alt='' src={require('./SILAC-vector.png')} style={{height: "auto", width: "220px", marginRight: "30px"}}/>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    
                </div>
            </Navbar>

        </React.Fragment>
    );
}

export default Example;