import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import UploadPortal from './components/pages/UploadPortal';
import './App.css';

function App() {
  return (
    <Router>
				<div className='App'>
					<Navbar />
					<Route path='/' component={UploadPortal} />
				</div>
				
			</Router>

			
  );
}

export default App;
